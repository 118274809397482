.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loader {
    width: 600px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .loader p {
    margin: 0;
    font-size: 18px;
  }
  
  .loader .bar {
    background-color: #bd24b3;
    height: 100px;
    width: 40px;
    display: inline-block;
    margin: 0 10px;
    animation: loader-animation 1.2s infinite ease-in-out;
  }
  
  @keyframes loader-animation {
    0%, 100% { transform: scaleY(1.0); }
    50% { transform: scaleY(2.0); }
  }
  
  .loader .bar:nth-child(2) {
    animation-delay: -0.4s;
  }
  .loader .bar:nth-child(3) {
    animation-delay: -0.3s;
  }
  .loader .bar:nth-child(4) {
    animation-delay: -0.2s;
  }
  .loader .bar:nth-child(5) {
    animation-delay: -0.1s;
  }
  